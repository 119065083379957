import { SerializedDisplayPlan } from '@gijirokukun/shared'
import {
  ProductTypeToIDsMap,
  SubscriptionDetailsMap,
  SubscriptionType,
} from '@gijirokukun/shared'
import { isProduction } from 'src/config'
import firebase from 'firebase/compat/app'

export type Item = firebase.analytics.Item & {
  // 何故か無いがドキュメントにはある
  currency?: ValidCurrency
}

export const ITEMS = Object.entries(SubscriptionDetailsMap).reduce(
  (items, [_type, details]) => {
    const type = _type as SubscriptionType
    items[type] = {
      item_category: 'plan',
      item_id: ProductTypeToIDsMap(isProduction)[type].prodId,
      item_name: details.longName,
      // 価格は決定出来ないので設定できない
    }
    return items
  },
  {} as Record<SubscriptionType, Item>
)

const PLAN_ITEM_LIST = Object.values(ITEMS)

export const ITEM_LISTS = {
  LANDING_PLAN_LIST: {
    item_list_id: 'LANDING_PLAN_LIST',
    item_list_name: 'Landing page plans',
    items: PLAN_ITEM_LIST,
  },
  APP_PLAN_LIST: {
    item_list_id: 'APP_PLAN_LIST',
    item_list_name: 'Plan page plan list',
    items: PLAN_ITEM_LIST,
  },
} as const

export type ValidCurrency = 'JPY'

export type Cart = {
  // 価格は基本的に決定出来ないので設定できない
  // currency: ValidCurrency
  // value: number
  items: Item[]
}

type LogEventTypesFactory<
  T extends Record<string, Record<string, unknown> | undefined>,
> = T

/**
 * ! 40文字以内にしなくてはいけない
 */
export type LogEventTypes = LogEventTypesFactory<
  {
    [K in `new_gijiroku_template_to_${string}`]: undefined
  } & {
    [K in `gijiroku_menu_board_download_${string}`]: undefined
  } & {
    [K in `gijiroku_menu_download_${string}`]: undefined
  } & {
    // 連携
    [K in `${string}Linkage_opened`]: undefined // 認証画面を開いた
  } & {
    [K in `${string}Linkage_clicked`]: undefined // 連携ボタンが押された
  } & {
    [K in `${string}Linkage_refunded`]: undefined // 拒否ボタンが押された
  } & {
    [K in `${string}Linkage_confirmed`]: undefined // 認証画面で認証して帰ってきた
  } & {
    [K in `${string}Linkage_cancelled`]: undefined // アカウント画面で連携解除
  } & {
    // 新規議事録ページ
    new_gijiroku_create: undefined
    new_gijiroku_create_from_mic: undefined
    new_gijiroku_create_from_zoom: undefined
    new_gijiroku_create_from_msteams: undefined
    new_gijiroku_create_from_meet: undefined
    new_gijiroku_create_from_sched_zoom: undefined
    new_gijiroku_create_from_sched_msteams: undefined
    new_gijiroku_create_from_file: undefined
    new_gijiroku_create_by_authed: undefined
    new_gijiroku_create_by_guest: undefined
    new_gijiroku_save_change_to_mygijiroku: undefined
    new_gijiroku_save_change_to_team: undefined
    new_gijiroku_selected_folder_to_team: undefined
    new_gijiroku_selected_folder_to_user: undefined
    new_gijiroku_selected_template_to_room: undefined
    new_gijiroku_folder_create: undefined

    // 議事録 - 入退室
    gijiroku_join: undefined
    gijiroku_join_with_public: undefined
    gijiroku_join_with_token: undefined
    gijiroku_join_with_password: undefined
    gijiroku_join_user_isAuthed: undefined
    gijiroku_join_user_isGuest: undefined
    gijiroku_join_nickname_set: undefined
    gijiroku_join_password_set: undefined
    gijiroku_join_go_to_top: undefined

    // 議事録 - 状態
    gijiroku_status_start: undefined
    gijiroku_status_pause: undefined
    gijiroku_status_pause_force_alert: undefined
    gijiroku_status_resume: undefined
    gijiroku_status_end: undefined
    gijiroku_status_end_f_by_max_gijiroku: undefined
    gijiroku_status_end_f_by_max_payer: undefined
    gijiroku_status_with_bot: undefined

    // 議事録 - 使用
    gijiroku_action_any_digest: undefined // hotjarで要約機能関係を使っている人を見る用
    gijiroku_action_speech_submitted: undefined // 発話が認識されたか（一言も喋っていない人を探すため）
    gijiroku_action_recording_aborted: undefined
    gijiroku_action_recording_unexpected: undefined
    gijiroku_action_section_create_on_digest: undefined
    gijiroku_action_section_insert_to_dial: undefined
    gijiroku_action_dialogue_edit_blur: undefined
    gijiroku_action_dialogue_edit_to_digest: undefined
    gijiroku_action_dialogue_edit_to_ndigest: undefined
    gijiroku_action_dialogue_play: undefined
    gijiroku_action_dialogue_pause: undefined
    gijiroku_action_dialogue_stop: undefined
    gijiroku_action_memo_add: undefined
    gijiroku_action_presection_add: undefined
    gijiroku_action_noteline_edit_blur: undefined
    gijiroku_action_noteline_delete: undefined
    gijiroku_action_noteline_options_open: undefined
    gijiroku_action_jump: undefined
    gijiroku_action_mic_on: undefined
    gijiroku_action_mic_off: undefined
    gijiroku_action_drag_end: undefined
    gijiroku_action_resize: undefined

    // 議事録 - メニュー
    gijiroku_menu_download_popup_clicked: undefined
    gijiroku_menu_share_popup_clicked: undefined
    gijiroku_menu_perm_popup_closed: undefined
    gijiroku_menu_language_change_sl: {
      languages: string[]
    }
    gijiroku_menu_language_change_tl: {
      languages: string[]
    }
    gijiroku_menu_share_type_urlPlain: undefined
    gijiroku_menu_share_type_urlWithToken: undefined
    gijiroku_menu_share_type_info: undefined
    share: {
      method: string
      content_type: string
      item_id: string
    }
    gijiroku_menu_bot_invite_clicked: undefined
    gijiroku_menu_bot_invite_failed: undefined
    gijiroku_menu_bot_invite_canceled: undefined
    gijiroku_menu_bot_invite_submitted: undefined // ※新規議事録に飛ぶので、完了した訳ではない
    gijiroku_menu_bot_req_toast_showed: undefined
    gijiroku_menu_share_protect_onlyOrg: undefined
    gijiroku_menu_share_protect_onlyTeam: undefined
    gijiroku_menu_share_protect_anyone: undefined
    gijiroku_menu_board_showed: undefined
    gijiroku_menu_board_share: undefined
    gijiroku_menu_board_next_gijiroku_crate: undefined
    gijiroku_menu_board_next_gijiroku_open: undefined
    gijiroku_menu_board_click_signup: undefined
    gijiroku_menu_board_download_audio: undefined
    gijiroku_menu_lets_recording_opened: undefined
    gijiroku_menu_lets_recording_cancel: undefined
    gijiroku_menu_lets_recording_rec: undefined
    gijiroku_menu_lets_recording_help: undefined
    gijiroku_menu_lets_recording_dont_show: undefined
    gijiroku_menu_download_audio: undefined

    // 警告
    warning_gijiroku_detect_poor_mic: undefined

    // ナビバー
    nav_gijiroku_create: undefined
    nav_connect_to_zoom_clicked: undefined
    nav_upgrade_clicked: undefined
    nav_help_clicked: undefined
    nav_words_clicked: undefined
    nav_opened: undefined
    nav_closed: undefined
    nav_guest_login: undefined
    nav_guest_signup: undefined

    // ホーム
    home_room_create: undefined

    // My議事録
    mygijiroku_folder_create: undefined
    mygijiroku_room_create: undefined
    mygijiroku_room_rename: undefined
    mygijiroku_room_delete: undefined

    // チーム
    team_create_for_non_organization: undefined
    team_create_for_organization: undefined
    team_folder_create: undefined
    team_room_create: undefined
    team_room_rename: undefined
    team_room_delete: undefined
    team_user_kick: undefined
    team_delete_popup_open: undefined
    team_delete: undefined
    team_join: undefined
    team_enter: undefined
    team_join_failed_by_limit_exceeded: undefined
    team_join_failed_by_token_expired: undefined
    team_join_failed_by_not_found: undefined
    team_share: undefined

    // アカウント
    login: {
      method: 'email' | 'google'
    }
    sign_up: {
      method: 'email' | 'google'
    }
    logout: undefined
    is_paid_user: undefined // 主にhotjarで課金ユーザーでフィルタリングする用
    account_email_change: undefined
    account_username_change: undefined
    account_logout_all_sessions: undefined
    account_personalInformation_popup_open: undefined
    account_personalInformation_popup_submit: undefined
    account_questionnaire_opened: undefined
    account_multifactor_enable_clicked: undefined
    account_multifactor_disable_clicked: undefined
    account_zoom_botname_change: undefined
    account_msteams_botname_change: undefined
    account_meet_botname_change: undefined
    account_filler_elimination_enable: undefined
    account_filler_elimination_disable: undefined

    // ランディングページ
    landing_manual_click: undefined
    landing_download_manual_click: undefined
    landing_user: undefined
    landing_scroll_to_pricing: undefined
    landing_plan_expanded: undefined
    landing_plan_select_with_unsup_mes: undefined
    landing_plan_clicked: { planV2Type: SerializedDisplayPlan }
    send_inquiry: undefined

    // 組織
    organization_zoom_botname_change: undefined
    organization_msteams_botname_change: undefined
    organization_meet_botname_change: undefined
    organization_member_usage_export: undefined

    // 支払
    view_item_list: {
      item_list_id: string
      item_list_name: string
      items: Item[]
    }
    select_item: {
      item_list_id: string
      item_list_name: string
      items: Item[]
    }
    add_to_cart: Cart
    remove_from_cart: Cart
    begin_checkout: Cart
    purchase: {
      transaction_id: string
    } & Cart
    billing_change_sub_open_with_change: undefined
    billing_change_sub_confirm: undefined
    billing_change_sub_cancel: undefined
    billing_open_customer_portal: undefined
    billing_stripe_checkout: undefined // Stipeのチェックアウトを呼んだ時。変更の場合は記録されない。
    billing_plan_expanded: undefined
    billing_organization_checkout: undefined

    // Google認証
    googleLinkage_started: undefined
    googleLinkage_confirmed: undefined // Googleの認証画面で認証して帰ってきた
    googleCalendarLinkage_started: undefined
    googleCalendarLinkage_cancelled: undefined // アカウント画面で連携解除

    // スケジュール
    schedule_wishTo_autoJoin_enabled: undefined
    schedule_wishTo_autoJoin_disabled: undefined
    schedule_clicked: undefined
    schedule_invite_clicked: undefined
    schedule_join_liveMeeting_clicked: undefined
    schedule_join_liveMeeting_failed: undefined
    schedule_join_liveMeeting_canceled: undefined
    schedule_join_liveMeeting_submitted: undefined // ※新規議事録に飛ぶので、完了した訳ではない
    schedule_linkTo_zoom_clicked: undefined
    schedule_linkTo_msteams_clicked: undefined
    schedule_linkTo_google_clicked: undefined
    schedule_renew_clicked: undefined
    schedule_meeting_settings_clicked: undefined
    schedule_settings_open: undefined

    // サービス
    service_unsupported_show: undefined

    // ページ
    page_view: {
      page_title?: string
      page_location?: string
      page_path?: string
      [key: string]: any
    }
    screen_view: {
      firebase_screen: string
      firebase_screen_class: string
      [key: string]: any
    }

    // 購読
    subscribe_send_minutes: undefined // functionsからAnalyticsが使えないため、議事録終了時に送るとき自動で終了してfunctionsで送られる場合を考慮すると、このイベントは発火出来ない…
    subscribe_resub_minutes: undefined
    subscribe_unsub_minutes: undefined
    subscribe_send_news: undefined
    subscribe_resub_news: undefined
    subscribe_unsub_news: undefined

    // 単語登録
    words_single_add: undefined
    words_bulk_add: undefined
    words_org_create: undefined
    words_org_reject: {
      reason: string
    }
    words_org_update: undefined
    words_org_delete: undefined
    words_org_download: undefined

    // ヘルプ
    help_manual_click: undefined

    // フィードバック
    feedback_open: undefined
    feedback_submit: undefined
  }
>

// EVIL MAGIC https://stackoverflow.com/a/50375286/7923918
type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I
) => void
  ? I
  : never

export type LogEventFuncType = UnionToIntersection<
  {
    [K in keyof LogEventTypes]: LogEventTypes[K] extends undefined
      ? (eventName: K) => void
      : (eventName: K, eventParams: LogEventTypes[K]) => void
  }[keyof LogEventTypes]
>
